import * as constants from '@config/constants';

export const setCssBrandColors = () => {
  document.documentElement.style.setProperty(
    '--brand-color',
    constants.BRAND_COLOR
  );
  document.documentElement.style.setProperty(
    '--brand-accent-color',
    constants.BRAND_ACCENT_COLOR
  );
  document.documentElement.style.setProperty(
    '--light-color',
    constants.LIGHT_COLOR
  );
  document.documentElement.style.setProperty(
    '--dark-color',
    constants.DARK_COLOR
  );
  document.documentElement.style.setProperty(
    '--error-color',
    constants.ERROR_COLOR
  );
  document.documentElement.style.setProperty(
    '--success-color',
    constants.SUCCESS_COLOR
  );
};
