import React from 'react';

export interface ModalProps {
  children?: React.ReactNode;
}

const Modal = ({ children }: ModalProps): JSX.Element => {
  return (
    <div
      data-testid="modal-background"
      className="absolute z-50 top-0 left-0 flex items-center justify-center w-full h-full bg-gray-600 bg-opacity-50 min-h-screen"
    >
      <div className="flex flex-col items-center justify-center m-12 bg-white rounded-md p-4 max-w-md shadow-lg">
        {children}
      </div>
    </div>
  );
};

export default Modal;
