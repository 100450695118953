import React, { useEffect } from 'react';
import { Tool, Check, X } from 'react-feather';
import { Modal } from '@components/Modal';
import { rumAction } from '@config/constants';

export interface InstallationPromptProps {
  onDeclineInstallation: () => void;
  onAcceptInstallation: () => void;
  renderDelay?: number;
}

const InstallationPrompt = ({
  onDeclineInstallation,
  onAcceptInstallation,
  renderDelay = 1_000, // how long to wait before rendering the prompt
}: InstallationPromptProps): JSX.Element => {
  const [showInstallationPrompt, setShowInstallationPrompt] =
    React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowInstallationPrompt(true);
    }, renderDelay);
  }, []);

  return showInstallationPrompt ? (
    <Modal>
      <Tool className="w-12 h-12 text-brand" />
      <div className="w-full h-4" />
      <h2 className="text-brand text-center">Improved Tour Experience</h2>
      <div className="w-full h-4" />
      <p className="text-center text-md">Would you like to update?</p>
      <div className="w-full h-6" />
      <div className="flex w-full justify-around">
        <button
          data-testid="decline-installation"
          onClick={onDeclineInstallation}
          data-dd-action-name={rumAction.DECLINE_SERVICE_WORKER_UPDATE}
          className="bg-white border-2 border-brand text-brand p-2 rounded-md"
        >
          {/* No, thanks */}
          <X className="w-8 h-8" />
        </button>
        <div className="w-4" />
        <button
          data-testid="accept-installation"
          onClick={onAcceptInstallation}
          data-dd-action-name={rumAction.ACCEPT_SERVICE_WORKER_UPDATE}
          className="bg-brand text-white p-2 rounded-md"
        >
          {/* Yes, please */}
          <Check className="w-8 h-8 text-white" />
        </button>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default InstallationPrompt;
