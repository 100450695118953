import logger from './logger';

export class ExpiryError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ExpiryError';
  }
}

export const setLocalStorageWithExpiry = <T>(
  key: string,
  record: T,
  expiry: Date
) => {
  if (expiry < new Date()) throw new ExpiryError(expiry);
  localStorage.setItem(key, JSON.stringify({ _r: record, _x: expiry }));
};

export const getLocalStorageWithExpiry = <T>(key: string): T | null => {
  const record = localStorage.getItem(key);
  logger.debug(
    record
      ? 'Record found in local storage'
      : `Record not found in local storage: ${key}`,
    { record: record }
  );
  if (!record) return null;
  const { _r, _x } = JSON.parse(record);
  if (new Date(_x) < new Date()) {
    logger.debug('Record has expired', { record: _r, expiry: _x });
    return null;
  }
  logger.debug('Record has been found and is valid', { record: _r });
  return _r;
};

export const getAllLocalStorageItems = () => {
  const items = {} as { [key: string]: string | null };
  const keys = Object.keys(localStorage);
  let i = keys.length;
  logger.debug(`There are ${i} items in local storage`, { keys: keys });
  if (i > 0) {
    while (i--) {
      items[keys[i]] = localStorage.getItem(keys[i]);
    }
    logger.debug(`Found ${items.length} items in local storage`, {
      items: items,
    });
  }
  return items;
};
