import {
  getAccessRecord,
  setAccessRecord as setAccessRecordInLocalStorage,
} from '@utils/accessRecord/accessRecord';
import React, {
  createContext,
  useEffect,
  ReactNode,
  useState,
  useContext,
  Dispatch,
} from 'react';

interface AccessRecordProviderProps {
  children: ReactNode;
}

interface IAccessRecordContext {
  accessRecord: AccessRecord | null | undefined;
  setAccessRecord: Dispatch<AccessRecord | null> | (() => void);
}

const AccessRecordContext = createContext<IAccessRecordContext>(
  {} as IAccessRecordContext
);

const AccessRecordProvider = ({ children }: AccessRecordProviderProps) => {
  const [accessRecord, setAccessRecord] = useState<
    AccessRecord | null | undefined
  >(undefined);

  useEffect(() => {
    setAccessRecord(getAccessRecord());
  }, []);

  const value = {
    accessRecord,
    setAccessRecord,
  };

  useEffect(() => {
    accessRecord &&
      setAccessRecordInLocalStorage(accessRecord, accessRecord.expiry);
  }, [accessRecord]);

  return (
    <AccessRecordContext.Provider value={value}>
      {children}
    </AccessRecordContext.Provider>
  );
};

const useAccessRecord = () => {
  const context = useContext(AccessRecordContext);
  if (context === undefined) {
    throw new Error(
      'useAccessRecord must be used within a AccessRecordProvider'
    );
  }
  return context;
};

export { AccessRecordProvider, useAccessRecord };
