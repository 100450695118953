import { ACCESS_RECORD_KEY } from '@config/constants';
import {
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from '@utils/localStorageWithExpiry';
import logger from '@utils/logger';

/*
 * AccessRecord Utilities
 * Provides an abstraction over access control logic and access control record storage
 */

const SCHEMA_VERSION = { _version: 1 };

export class InvalidExpiryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidExpiryError';
  }
}

const setAccessRecord = (accessRecord: AccessRecord, expiry: Date) => {
  if (expiry < new Date()) {
    throw new InvalidExpiryError('Expiry date cannot be in the past');
  }
  setLocalStorageWithExpiry<AccessRecord>(
    ACCESS_RECORD_KEY,
    { ...accessRecord, ...SCHEMA_VERSION },
    expiry
  );
  logger.debug('Access record stored in localStorage', {
    accessRecord: accessRecord,
  });
};

const getAccessRecord = (): AccessRecord | null => {
  const accessRecord = getLocalStorageWithExpiry<
    AccessRecord & { _version: number }
  >(ACCESS_RECORD_KEY);
  if (accessRecord) {
    logger.debug('Access record has been found in local storage', {
      accessRecord,
    });
    accessRecord.expiry = new Date(accessRecord.expiry);
  }
  return accessRecord;
};

const isAccessGranted = (accessRecord: AccessRecord | null, tourId: string) => {
  if (accessRecord === null) {
    logger.debug('Access record is null');
    logger.debug('Re-checking for accessRecord', {
      accessRecord: getAccessRecord(),
    });
    return false;
  }
  logger.debug('Access record', { accessRecord: accessRecord });
  if (new Date() >= accessRecord.expiry) {
    logger.debug('Access record has expired');
    return false;
  }
  if (accessRecord) {
    const tourIdsMatch = accessRecord.tourId === tourId;
    logger.debug(`Tour IDs match: ${tourIdsMatch}`);
    return tourIdsMatch;
  }
  return false;
};

export { setAccessRecord, isAccessGranted, getAccessRecord };
