export const IMAGE_TYPE = 'image';
export const AUDIO_TYPE = 'audio';
export const BRAND_COLOR = '#115571';
export const BRAND_ACCENT_COLOR = '#EEBD5D';
export const LIGHT_COLOR = '#e8f6f7';
export const DARK_COLOR = '#222F34';
export const ERROR_COLOR = '#ef4444';
export const SUCCESS_COLOR = '#00A96C';
export const CURRENT_PLAYBACK_POSITION_KEY = 'currentPlaybackPosition';
export const MINIMUM_IOS_VERSION = 12.2;
export const VISITOR_ID_KEY = 'visitor-id';
export const rumContext = {
  AUTO_ENROL: 'autoEnrol',
};
export const rumAction = {
  STORAGE_CAPACITY_CALCULATED: 'storageCapacityCalculated',
  STORAGE_CAPACITY_CALCULATION_UNAVAILABLE:
    'storageCapacityCalculationUnavailable',
  WIZARD_CLICK_PREVIOUS: 'wizardClickPrevious',
  WIZARD_CLICK_NEXT: 'wizardClickNext',
  AUDIO_PLAY: 'audioClickPlay',
  AUDIO_PAUSE: 'audioClickPause',
  AUDIO_SCRUB: 'audioScrub',
  AUDIO_PREVIOUS: 'audioClickPrevious',
  AUDIO_NEXT: 'audioClickNext',
  TOUR_END_RETURN_TO_START: 'clickReturnToStart',
  MENU_ITEM: 'clickMenuItem',
  OPEN_MENU: 'clickOpenMenu',
  CLOSE_MENU: 'clickCloseMenu',
  OPEN_MAP: 'clickOpenMap',
  CLOSE_MAP: 'clickCloseMap',
  BEGIN_TOUR: 'clickBeginTour',
  ACCEPT_SERVICE_WORKER_UPDATE: 'acceptServiceWorkerUpdate',
  DECLINE_SERVICE_WORKER_UPDATE: 'declineServiceWorkerUpdate',
};
export const rumView = {
  TOUR_SEGMENT: 'tourSegment',
};
export const ACCESS_RECORD_KEY = 'access-record';
export const TOUR_RECORD_KEY = 'tour-record';
export const TEXT_ANIMATION_START_DELAY = 300;
export const TEXT_ANIMATION_AVG_DELAY = 10;
