import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { setContext } from '@apollo/client/link/context';
import { getAccessRecord } from '@utils/accessRecord/accessRecord';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_VISITOR_API_URL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Api-Key': process.env.NEXT_PUBLIC_VISITOR_API_KEY,
      authorization: getAccessRecord()?.token || 'NOT_SET',
    },
  };
});

export const initClient = (): ApolloClient<NormalizedCacheObject> => {
  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });
};
