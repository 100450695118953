import { datadogLogs } from '@datadog/browser-logs';

const logToConsole = process.env.NEXT_PUBLIC_LOG_TO_CONSOLE || false;

datadogLogs.init({
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env:
    process.env.NEXT_PUBLIC_LOGGER_ENV ||
    process.env.NEXT_PUBLIC_DEPLOY_ENV ||
    'not set',
  service: 'visitor-app',
});

const error = (...args): void => {
  datadogLogs.logger.error(args[0], {
    ...args,
  });
  logToConsole && console.error(...args);
};

const warning = (...args): void => {
  datadogLogs.logger.warn(args[0], {
    ...args,
  });
  logToConsole && console.warn(...args);
};

const info = (...args): void => {
  datadogLogs.logger.info(args[0], {
    ...args,
  });
  logToConsole && console.info(...args);
};

const debug = (...args): void => {
  datadogLogs.logger.debug(args[0], {
    ...args,
  });
  logToConsole && console.debug(...args);
};

const logger = {
  error,
  warning,
  info,
  debug,
};

export default logger;
