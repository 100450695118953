import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: 'visitor-app',
  env:
    process.env.NEXT_PUBLIC_LOGGER_ENV ||
    process.env.NEXT_PUBLIC_DEPLOY_ENV ||
    'not-set',
  version:
    process.env.NEXT_PUBLIC_APP_VERSION ||
    process.env.NEXT_PUBLIC_BUILD_ID ||
    'not-set',
  sampleRate: 100,
  trackInteractions: true,
  proxyHost:
    process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
      ? 'ux.api.gydeus.org'
      : 'ux.api.gydeus-dev.org',
});
