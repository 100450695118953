/* 
  NOTE: This file should be changed with caution
  It handles the service worker lifecycle - specifically how we manage running the app on devices that have previously had the app running
  It has proven to be difficult to write Unit and Integration/e2e tests for this class. As a result it's advised that any changes are followed by the following manual regression testing:
  - Running an existing version (pre-change) of the app on a branch and navigating to it on a device
  - Deploying the changed version of the app to the same branch
  - Refreshing the page on the device that has navigated to the deployed version of the app
  What we would expect to see:
  - A prompt asking the user if they wish to upgrade to the latest version or not
  - If the user selects yes: The page should refresh and give the user the new version of the app
  - If the user selects no: The prompt should disappear and the app continue working as before
*/

import { useEffect, useState } from 'react';
import logger from '@utils/logger';
import { Workbox } from 'workbox-window';

export enum ServiceWorkerEvent {
  INSTALLED = 'installed',
  WAITING = 'waiting',
  ACTIVATED = 'activated',
  CONTROLLING = 'controlling',
  REDUNDANT = 'redundant',
}

export enum ServiceWorkerState {
  UNKNOWN = 'unknown', // Default - The code has not yet been able to derive the state
  UNSUPPORTED = 'unsupported', // The browser does not support service workers
  REGISTERED = 'registered', // The browser is aware of the service worker and can interact with it - it may or may not be controlling the page
  INSTALLING = 'installing', // The browser is making the service worker available
  INSTALLED = 'installed', // The browser has made the service worker available to be activated
  ACTIVATING = 'activating', // The browser is in the process of giving control to the service worker
  ACTIVATED = 'activated', // The service worker is now ready to control the page (before the page has been refreshed)
  CONTROLLING = 'controlling', // The service worker is now controlling the page (after the page has been refreshed)
  REDUNDANT = 'redundant', // The service worker is no longer activated or controlling the page
}

/* 
  This hook is used to listen to service worker lifecycle events. The lifecycle of a service worker is as follows:
  1. Registered - the browser is aware of the version of the service worker
  2. Installed - the service worker is ready to create and manage its cache and to start caching assets. Note: in this state the service worker is not actively controlling the page
  3. Activated - the service worker is controlling the page and is ready to handle fetch events

  The following diagram uses [State] and (Event) to represent the lifecycle of the service worker.
  [Registered] -> (Installing) -> [Installed] -> (Activating) -> [Activated] -> page refresh -> [CONTROLLING] -> [Redundant]

  The hook returns:
    state: (ServiceWorkerState) the current state of the service worker
    ignoreNewVersion: (boolean) if true, the hook will ignore the new version of the service worker
      - set globally when someone 'declines' to update
    newVersionReadyToActivate: (boolean) whether or not the service worker is ready to be activated
    acceptNewVersion: (() => void) a function to be called to accept the update
    declineNewVersion: (() => void) a function to be called to decline the update
*/
const useServiceWorkerLifecycle = (): {
  state: ServiceWorkerState;
  ignoreNewVersion: boolean;
  newVersionReadyToActivate: boolean;
  acceptNewVersion: () => void;
  declineNewVersion: () => void;
} => {
  const [state, setState] = useState<ServiceWorkerState>(
    ServiceWorkerState.UNKNOWN
  );
  const [ignoreNewVersion, setIgnoreNewVersion] = useState(false);
  const [serviceWorker, setServiceWorker] = useState<Workbox | null>(null);
  const [reloadToActivateRequired, setReloadToActivateRequired] =
    useState(false);
  // This hook only runs once in browser after the component is rendered for the first time.
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox;
      logger.debug('[SWLC] PWA is supported');
      setServiceWorker(wb);
      wb.getSW().then((sw) => {
        logger.debug(`[SWLC] Service worker state is ${sw.state}`);
      });
      wb.register(); // Inform the browser about this version of the service worker
      setState(ServiceWorkerState.REGISTERED);

      wb.addEventListener(ServiceWorkerEvent.INSTALLED, (e) => {
        logger.debug('[SWLC] [installed]', e);
        // It should be possible to precache assets here.
        setState(ServiceWorkerState.INSTALLED);
      });
      wb.addEventListener(ServiceWorkerEvent.WAITING, (e) => {
        logger.debug('[SWLC] [waiting]', e);
        setState(ServiceWorkerState.INSTALLED);
      });
      wb.addEventListener(ServiceWorkerEvent.ACTIVATED, (e) => {
        logger.debug('[SWLC] [activated]', e);
        setState(ServiceWorkerState.ACTIVATED);
      });
      wb.addEventListener(ServiceWorkerEvent.CONTROLLING, (e) => {
        logger.debug('[SWLC] [controlling]', e);
        setState(ServiceWorkerState.CONTROLLING);
        // Reloading is only required when the service worker is replacing on existing service worker
        // If the service worker is being activated for the first time then reloading is not required
        if (reloadToActivateRequired) {
          logger.debug('[SWLC] Reloading to activate updated service worker');
          window.location.reload();
        } else {
          logger.debug('[SWLC] Reload not required');
        }
      });
      wb.addEventListener(ServiceWorkerEvent.REDUNDANT, (e) => {
        logger.debug('[SWLC] [redundant]', e);
        setState(ServiceWorkerState.REDUNDANT);
      });
    } else {
      logger.warning('[SWLC] PWA is not supported');
      setState(ServiceWorkerState.UNSUPPORTED);
    }
  }, []);

  const acceptNewVersion = () => {
    if (serviceWorker) {
      logger.info('[SWLC] Accepting new version');
      serviceWorker.addEventListener(
        // Add this listener before we send "skip waiting" to ensure it is ready when the service worker is activated
        ServiceWorkerEvent.CONTROLLING,
        (event) => {
          logger.debug(
            `[SWLC] [controlling] Service worker has been activated`
          );
        }
      );

      // A user instigated service worker update is only ever done for a service worker which is replacing an old version
      // a reload is required to allow the new service worker to take control of the page
      setReloadToActivateRequired(true);
      // Send a message to the waiting service worker, instructing it to activate.
      serviceWorker.messageSkipWaiting();
      setState(ServiceWorkerState.ACTIVATING);
    }
  };

  const declineNewVersion = () => {
    logger.info(`[SWLC] Declining new version`);
    setIgnoreNewVersion(true);
  };

  return {
    state,
    ignoreNewVersion,
    newVersionReadyToActivate:
      state === ServiceWorkerState.INSTALLED && !ignoreNewVersion,
    acceptNewVersion,
    declineNewVersion,
  };
};

export default useServiceWorkerLifecycle;
