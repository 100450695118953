import { UAParser } from 'ua-parser-js';
import logger from '@utils/logger';
import { MINIMUM_IOS_VERSION } from '@config/constants';

export const isDeviceCompatible = (): boolean => {
  const ua = UAParser();
  const osVersion = parseFloat(ua.os.version || '999');
  if (ua.os.name?.toLowerCase() === 'ios' && osVersion < MINIMUM_IOS_VERSION) {
    logger.warning('This device is NOT supported');
    return false;
  } else {
    logger.debug('This device IS supported!');
    return true;
  }
};

export const isBrowserCompatible = (): boolean => {
  const ua = UAParser();
  if (
    ua.browser.name?.toLowerCase() !== 'mobile safari' &&
    ua.browser.name?.toLowerCase() !== 'safari' &&
    ua.os.name === 'iOS'
  ) {
    logger.warning('This device/browser combination is NOT supported');
    return false;
  } else {
    logger.debug('This device/browser combination IS supported!');
    return true;
  }
};
