export const HOME = '/';
export const AUTH_FAILED = '/';
export const SITES_BASE = '/app/sites/';
export const SITE_BASE = '/app/sites/site/';
export const TOURS_BASE = '/app/sites/tours/';
export const TOUR_BASE = '/app/sites/tours/tour/';
export const TOUR_WELCOME = '/app/sites/tours/tour/welcome/';
export const TOUR_END = '/app/sites/tours/tour/end/';
export const HELP = '/app/help/';
export const SAFARI_LOGO = '/images/safari-browser-logo.svg';
export const CHROME_LOGO = '/images/chrome-browser-logo.svg';
export const LIKE_THIS_ICON = '/images/like-this.svg';
export const NOT_THIS_ICON = '/images/not-this.svg';
export const UNSUPPORTED_BROWSER = '/unsupported-browser/';
export const UNSUPPORTED_DEVICE = '/unsupported-device/';
