import '@css/tailwind.css';
import React, { useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { initClient } from '@lib/apolloClient';
import '@utils/realUserMonitoring';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { setCssBrandColors } from '@utils/styleUtils';
import logger from '@utils/logger';
import { useRouter } from 'next/router';
import { UNSUPPORTED_BROWSER, UNSUPPORTED_DEVICE } from '@config/routes';
import { isBrowserCompatible, isDeviceCompatible } from '@utils/compatibility';
import { useServiceWorkerLifecycle } from '@hooks/useServiceWorkerLifecycle';
import { AccessRecordProvider } from '@providers/AccessRecordContextProvider';
import { InstallationPrompt } from '@components/InstallationPrompt';

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const { newVersionReadyToActivate, acceptNewVersion, declineNewVersion } =
    useServiceWorkerLifecycle();
  const router = useRouter();
  const setViewHeight = () => {
    const innerHeight =
      document.documentElement?.clientHeight || window.innerHeight;
    document.documentElement.style.setProperty(
      '--vh',
      innerHeight ? `${innerHeight}px` : '100vh'
    );
  };
  const [loading, setLoading] = useState<boolean>(true);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    // Configure screen size and colors
    setCssBrandColors();
    setViewHeight();
    window.addEventListener('resize', () => {
      setViewHeight();
    });
    setLoading(false);
    return window.removeEventListener('resize', () => {
      setViewHeight();
    });
  }, []);

  useEffect(() => {
    // Initialise apollo client
    setClient(initClient());
  }, []);

  useEffect(() => {
    // Compatibility checks
    if (!isDeviceCompatible()) {
      const incompatibleDeviceRoute = {
        pathname: UNSUPPORTED_DEVICE,
      };
      router.push(incompatibleDeviceRoute);
    }
    if (!isBrowserCompatible()) {
      const incompatibleBrowserRoute = {
        pathname: UNSUPPORTED_BROWSER,
        query: { previousUrlPath: router.asPath },
      };
      router.push(incompatibleBrowserRoute);
    }
  }, []);

  useEffect(() => {
    // Log out a page refresh
    window.addEventListener('beforeunload', () => {
      logger.info('Page refreshed');
    });
    return window.removeEventListener('beforeunload', () => {
      logger.info('Page refreshed');
    });
  }, []);

  useEffect(() => {
    // Prevent the mini-infobar from appearing on mobile
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
    });
    return window.removeEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
    });
  }, []);

  const Loading = () => {
    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen p-2 text-center min-h-96">
        <h3 className="my-6">Initialising Audio Tour App</h3>
        <div className="my-6">
          <a
            href={
              process.env.DEPLOY_ENV === 'production'
                ? 'http://app.gydeus.org'
                : 'http://app.gydeus-dev.org'
            }
          >
            Click here to continue
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <title>Gyde</title>
      </Head>
      {newVersionReadyToActivate && (
        <InstallationPrompt
          onAcceptInstallation={acceptNewVersion}
          onDeclineInstallation={declineNewVersion}
        />
      )}
      {loading || !client ? (
        <Loading />
      ) : (
        <ApolloProvider client={client}>
          <AccessRecordProvider>
            <div className="flex flex-col min-w-full min-h-screen">
              <Component {...pageProps} />
            </div>
          </AccessRecordProvider>
        </ApolloProvider>
      )}
    </>
  );
};

export default App;
